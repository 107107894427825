// src/components/ImageModal.tsx

import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './ImageModal.css';

interface ImageModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  currentImage: string;
  onNext: () => void;
  onPrev: () => void;
}

const ImageModal: React.FC<ImageModalProps> = ({
  isOpen,
  onRequestClose,
  currentImage,
  onNext,
  onPrev,
}) => {
  const [imageSize, setImageSize] = useState<{ width: number; height: number } | null>(null);

  useEffect(() => {
    if (isOpen) {
      const img = new Image();
      img.src = currentImage;
      img.onload = () => {
        setImageSize({ width: img.width, height: img.height });
      };
    }
  }, [currentImage, isOpen]);

  const getModalSize = () => {
    if (!imageSize) return { width: '70vw', height: 'auto' };

    const aspectRatio = imageSize.height / imageSize.width;
    const minWidth = 300;
    const maxWidth = Math.min(imageSize.width, window.innerWidth * 0.7);
    const width = Math.max(minWidth, maxWidth);
    const height = width * aspectRatio;

    return { width: `${width}px`, height: `${height}px` };
  };

  const modalSize = getModalSize();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="image-modal"
      overlayClassName="image-modal-overlay"
      style={{
        content: {
          minWidth: modalSize.width,
          maxWidth: modalSize.width,
          maxHeight: modalSize.height,
        },
      }}
    >
      <button className="image-modal-close" onClick={onRequestClose}>×</button>
      <img src={currentImage} alt="Modal" className="image-modal-img" />
    </Modal>
  );
};

export default ImageModal;
