import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./Footer.css";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-links">
        <a
          href="https://www.instagram.com/shreya.chaudhary"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        {/* <a
          href="https://www.tiktok.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
          <FontAwesomeIcon icon={faTiktok} />
        </a> */}
        <a
          href="https://models.com/models/shreya-chaudhary"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
          <FontAwesomeIcon icon={faUser} />
        </a>
        <a
          href="https://forms.gle/WJmrTTqvVVZhNJCw8"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
