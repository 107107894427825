// src/App.tsx

import React from "react";
import "./App.css";
import Footer from "./components/Footer";
import ImageGallery from "./components/ImageGallery";
import LandingPage from "./components/LandingPage";
import ModelInfo from "./components/ModelInfo";

const App: React.FC = () => {
  return (
    <div className="App">
      {/* <header className="App-header">
        <h1>Fashion Model Portfolio</h1>
      </header> */}
      <LandingPage />
      <div className="content">
        <section id="model-info" className="fading-effect">
          <ModelInfo
            name="Shreya Chaudhary"
            height="5'9"
            measurements="34-24-35"
            hairColor="Black"
            eyeColor="Dark Brown"
          />
        </section>

        <ImageGallery />
      </div>
      <Footer />
    </div>
  );
};

export default App;
