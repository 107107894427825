import React from 'react';
import Lottie from 'react-lottie';
import loadingAnimation from "./loading.json"; // Path to your Lottie animation file

const Loader: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
    <div className="loader-container" style={{display:"grid", gridAutoFlow: "column", gap: 12}}>
      <Lottie options={defaultOptions} height={400} width={400} style={{borderRadius: 8}}/>
      <Lottie options={defaultOptions} height={400} width={400} style={{marginTop: 60, borderRadius: 8}}/>
      <Lottie options={defaultOptions} height={400} width={400} style={{borderRadius: 8}}/>
    </div>
    <div className="loader-container" style={{display:"grid", gridAutoFlow: "column", gap: 12}}>
      <Lottie options={defaultOptions} height={400} width={400} style={{borderRadius: 8}}/>
      <Lottie options={defaultOptions} height={400} width={400} style={{marginTop: 60, borderRadius: 8}}/>
      <Lottie options={defaultOptions} height={400} width={400} style={{borderRadius: 8}}/>
    </div>
      </>
  );
};

export default Loader;
