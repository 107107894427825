import React from 'react';
import './Drawer.css';

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Drawer: React.FC<DrawerProps> = ({ isOpen, onClose, children }) => {
  return (
    <div className={`drawer ${isOpen ? 'open' : ''}`}>
      <button className="drawer-close-btn" onClick={onClose}>✕</button>
      <div className="drawer-content">
        {children}
      </div>
    </div>
  );
};

export default Drawer;
