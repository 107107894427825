import React, { useEffect, useState } from "react";
import "./LandingPage.css";

const LandingPage: React.FC = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="landing-page">
      <video autoPlay muted loop playsInline className="landing-video">
        <source src="./assets/Shreya_Film.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="landing-overlay">
        <h1 style={{ textShadow: " 0 0 15px rgba(255, 255, 255, 0.9)" }}>
          Shreya Chaudhary
        </h1>
      </div>
      <div
        className="video-overlay"
        style={{ height: `${Math.min(scrollY / 5, 100)}px` }} // Adjust the division factor and maximum height as needed
      />
    </div>
  );
};

export default LandingPage;
