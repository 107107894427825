export const staticImages = {
  digitals: [
    {
      id: 1,
      url: "/assets/digitals1.jpg",
      description: "Digital Image 1",
      tags: ["fashion"],
    },
    {
      id: 2,
      url: "/assets/digitals2.jpg",
      description: "Digital Image 2",
      tags: ["fashion"],
    },
    {
      id: 3,
      url: "/assets/digitals3.jpg",
      description: "Digital Image 2",
      tags: ["fashion"],
    },
    {
      id: 4,
      url: "/assets/digitals4.jpg",
      description: "Digital Image 2",
      tags: ["fashion"],
    },
    {
      id: 5,
      url: "/assets/digitals5.jpg",
      description: "Digital Image 2",
      tags: ["fashion"],
    },
    {
      id: 6,
      url: "/assets/digitals6.jpg",
      description: "Digital Image 2",
      tags: ["fashion"],
    },
    {
      id: 7,
      url: "/assets/digitals7.jpg",
      description: "Digital Image 2",
      tags: ["fashion"],
    },
    {
      id: 8,
      url: "/assets/digitals8.jpg",
      description: "Digital Image 2",
      tags: ["fashion"],
    },
    {
      id: 9,
      url: "/assets/digitals9.jpg",
      description: "Digital Image 2",
      tags: ["fashion"],
    },
    // Add more images as needed
  ],
  portfolio: [
    {
      id: 7,
      url: "/assets/1.jpg",
      description: "Portfolio Image 1",
      tags: ["beauty"],
    },
    {
      id: 8,
      url: "/assets/2.jpg",
      description: "Portfolio Image 2",
      tags: ["beauty"],
    },

    {
      id: 9,
      url: "assets/3.jpg",
      description: "Portfolio Image 3",
      tags: ["beauty"],
    },
    {
      id: 10,
      url: "assets/4.jpg",
      description: "Portfolio Image 4",
      tags: ["beauty"],
    },
    {
      id: 11,
      url: "assets/5.jpg",
      description: "Portfolio Image 5",
      tags: ["beauty"],
    },
    {
      id: 12,
      url: "assets/6.jpg",
      description: "Portfolio Image 6",
      tags: ["beauty"],
    },
    {
      id: 13,
      url: "assets/7.jpg",
      description: "Portfolio Image 7",
      tags: ["beauty"],
    },
    {
      id: 14,
      url: "assets/8.jpg",
      description: "Portfolio Image 8",
      tags: ["beauty"],
    },
    {
      id: 15,
      url: "assets/9.jpg",
      description: "Portfolio Image 9",
      tags: ["beauty"],
    },
    {
      id: 16,
      url: "assets/10.jpg",
      description: "Portfolio Image 10",
      tags: ["beauty"],
    },
    {
      id: 17,
      url: "assets/11.jpg",
      description: "Portfolio Image 11",
      tags: ["beauty"],
    },
    {
      id: 18,
      url: "assets/12.jpg",
      description: "Portfolio Image 12",
      tags: ["beauty"],
    },
    {
      id: 19,
      url: "assets/13.jpg",
      description: "Portfolio Image 13",
      tags: ["beauty"],
    },
    {
      id: 20,
      url: "assets/14.jpg",
      description: "Portfolio Image 14",
      tags: ["beauty"],
    },
    {
      id: 21,
      url: "assets/15.jpg",
      description: "Portfolio Image 15",
      tags: ["beauty"],
    },
    {
      id: 22,
      url: "assets/16.jpg",
      description: "Portfolio Image 16",
      tags: ["beauty"],
    },
    {
      id: 23,
      url: "assets/17.jpg",
      description: "Portfolio Image 17",
      tags: ["beauty"],
    },
    {
      id: 24,
      url: "assets/18.jpg",
      description: "Portfolio Image 18",
      tags: ["beauty"],
    },
    {
      id: 25,
      url: "assets/19.jpg",
      description: "Portfolio Image 19",
      tags: ["beauty"],
    },
    {
      id: 26,
      url: "assets/20.jpg",
      description: "Portfolio Image 20",
      tags: ["beauty"],
    },
    {
      id: 27,
      url: "assets/21.jpg",
      description: "Portfolio Image 21",
      tags: ["beauty"],
    },
    {
      id: 28,
      url: "assets/22.jpg",
      description: "Portfolio Image 22",
      tags: ["beauty"],
    },
    {
      id: 29,
      url: "assets/23.jpg",
      description: "Portfolio Image 23",
      tags: ["beauty"],
    },
    {
      id: 30,
      url: "assets/24.jpg",
      description: "Portfolio Image 24",
      tags: ["beauty"],
    },
    {
      id: 31,
      url: "assets/25.jpg",
      description: "Portfolio Image 25",
      tags: ["beauty"],
    },
    {
      id: 32,
      url: "assets/26.jpg",
      description: "Portfolio Image 26",
      tags: ["beauty"],
    },
    {
      id: 33,
      url: "assets/27.jpg",
      description: "Portfolio Image 27",
      tags: ["beauty"],
    },
    {
      id: 34,
      url: "assets/28.jpg",
      description: "Portfolio Image 28",
      tags: ["beauty"],
    },
    {
      id: 35,
      url: "assets/29.jpg",
      description: "Portfolio Image 29",
      tags: ["beauty"],
    },
    {
      id: 36,
      url: "assets/30.jpg",
      description: "Portfolio Image 30",
      tags: ["beauty"],
    },
    {
      id: 37,
      url: "assets/31.jpg",
      description: "Portfolio Image 31",
      tags: ["beauty"],
    },
    {
      id: 38,
      url: "assets/32.jpg",
      description: "Portfolio Image 32",
      tags: ["beauty"],
    },
    {
      id: 39,
      url: "assets/33.jpg",
      description: "Portfolio Image 33",
      tags: ["beauty"],
    },
    {
      id: 40,
      url: "assets/34.jpg",
      description: "Portfolio Image 34",
      tags: ["beauty"],
    },
    {
      id: 41,
      url: "assets/35.jpg",
      description: "Portfolio Image 35",
      tags: ["beauty"],
    },
    {
      id: 42,
      url: "assets/36.jpg",
      description: "Portfolio Image 36",
      tags: ["beauty"],
    },
    {
      id: 43,
      url: "assets/37.jpg",
      description: "Portfolio Image 37",
      tags: ["beauty"],
    },
    {
      id: 44,
      url: "assets/38.jpg",
      description: "Portfolio Image 38",
      tags: ["beauty"],
    },
    {
      id: 45,
      url: "assets/39.jpg",
      description: "Portfolio Image 39",
      tags: ["beauty"],
    },
    {
      id: 46,
      url: "assets/40.jpg",
      description: "Portfolio Image 40",
      tags: ["beauty"],
    },
    {
      id: 47,
      url: "assets/41.jpg",
      description: "Portfolio Image 41",
      tags: ["beauty"],
    },
    {
      id: 48,
      url: "assets/42.jpg",
      description: "Portfolio Image 42",
      tags: ["beauty"],
    },
    {
      id: 49,
      url: "assets/43.jpg",
      description: "Portfolio Image 43",
      tags: ["beauty"],
    },
    {
      id: 50,
      url: "assets/44.jpg",
      description: "Portfolio Image 44",
      tags: ["beauty"],
    },
    {
      id: 51,
      url: "assets/45.jpg",
      description: "Portfolio Image 45",
      tags: ["beauty"],
    },
    {
      id: 52,
      url: "assets/46.jpg",
      description: "Portfolio Image 46",
      tags: ["beauty"],
    },
    {
      id: 53,
      url: "assets/47.jpg",
      description: "Portfolio Image 47",
      tags: ["beauty"],
    },
    {
      id: 54,
      url: "assets/48.jpg",
      description: "Portfolio Image 48",
      tags: ["beauty"],
    },
    {
      id: 55,
      url: "assets/49.jpg",
      description: "Portfolio Image 49",
      tags: ["beauty"],
    },
    {
      id: 56,
      url: "assets/50.jpg",
      description: "Portfolio Image 50",
      tags: ["beauty"],
    },
    {
      id: 57,
      url: "assets/51.jpg",
      description: "Portfolio Image 51",
      tags: ["beauty"],
    },

    // Add more images as needed
  ],
};
