import React from 'react';
import './Filter.css';

interface FilterProps {
  tags: string[];
  selectedTag: string;
  onTagChange: (tag: string) => void;
}

const Filter: React.FC<FilterProps> = ({ tags, selectedTag, onTagChange }) => {
  return (
    <div className="filter-container">
      <label htmlFor="filter" className="filter-label">Filter by Tag:</label>
      <select
        id="filter"
        value={selectedTag}
        onChange={(e) => onTagChange(e.target.value)}
        className="filter-select"
      >
        <option value="">All</option>
        {tags.map(tag => (
          <option key={tag} value={tag}>{tag}</option>
        ))}
      </select>
    </div>
  );
};

export default Filter;
