// src/components/ModelInfo.tsx
import React from "react";
import "./ModelInfo.css";

interface ModelInfoProps {
  name: string;
  height: string;
  measurements: string;
  hairColor: string;
  eyeColor: string;
}

const ModelInfo: React.FC<ModelInfoProps> = ({
  name,
  height,
  measurements,
  hairColor,
  eyeColor,
}) => {
  return (
    <div className="model-info">
      <div className="model-details-card">
        <p>
          Beginning with a small college fashion show in her hometown in Mumbai,
          India, Shreya made it to some of the major fashion capitals in the
          world over a career span of 8 years. She has worked in New York, Los
          Angeles, London, Paris, Spain and Germany.
        </p>
        <p>
          She’s currently signed with Muse Model Management in New York, Marilyn
          in Paris and Traffic in Barcelona. During this time, she walked the
          runway for the iconic fashion house, Dior and fronted campaigns for
          Mac Cosmetics, FILA, Haus Labs, Nordstrom, Milk Cosmetics and Smashbox
          Cosmetics. Some of the other well known clients she’s worked with
          include Harrods, Hourglass Cosmetics, Merit, Nars, Glossier, Vogue,
          Elle, Harper’s Bazaar, Grazia, Cosmopolitan, Instyle Magazine, to name
          a few.
        </p>
        <p>
          Growing up in India, she rarely saw women who looked like her in
          mainstream media. This experience inspired her to pursue modeling as a
          way to represent her community on an international platform. She’s
          committed to challenging conventional beauty standards and hopes to
          encourage others to embrace their individuality and uniqueness.
        </p>
      </div>
      <div className="info-grid">
        <p>
          <strong>HEIGHT:</strong> {height}
        </p>
        <p>
          <strong>MEASUREMENTS:</strong> {measurements}
        </p>
        <p>
          <strong>HAIR COLOR:</strong> {hairColor.toUpperCase()}
        </p>
        <p>
          <strong>EYE COLOR:</strong> {eyeColor.toUpperCase()}
        </p>
      </div>
    </div>
  );
};

export default ModelInfo;
