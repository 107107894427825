// src/components/ImageCard.tsx

import React from 'react';
import './ImageCard.css';

interface ImageCardProps {
  src: string;
  alt: string;
}

const ImageCard: React.FC<ImageCardProps> = ({ src, alt }) => {
  return (
    <div className="image-card">
      <img src={src} alt={alt} />
    </div>
  );
};

export default ImageCard;
