import React, { useEffect, useMemo, useState } from "react";
import Drawer from "./Drawer";
import Filter from "./Filter";
import ImageCard from "./ImageCard";
import "./ImageGallery.css";
import ImageModal from "./ImageModal";
import Loader from "./Loader"; // Import the new Loader component
import { staticImages } from "./const";

const ImageGallery: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageId, setCurrentImageId] = useState(0);
  const [selectedTag, setSelectedTag] = useState<string>("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<"digitals" | "portfolio">(
    "digitals"
  );
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 1 second delay

    return () => clearTimeout(timer); // Cleanup on component unmount
  }, [activeTab, selectedTag]);

  const filteredImages = useMemo(() => {
    const imagesToFilter = staticImages[activeTab];
    if (selectedTag === "") {
      return imagesToFilter;
    } else {
      return imagesToFilter.filter((image) => image.tags.includes(selectedTag));
    }
  }, [selectedTag, activeTab]);

  const openModal = (index: number) => {
    setCurrentImageId(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = () => {
    const currentImg = filteredImages.findIndex(
      (img) => img.id === currentImageId
    );
    setCurrentImageId(currentImg + 1);
  };

  const prevImage = () => {
    setCurrentImageId((prevIndex) =>
      prevIndex === 0 ? filteredImages.length - 1 : prevIndex - 1
    );
  };

  const tags = Array.from(
    new Set(staticImages[activeTab].flatMap((image) => image.tags))
  );

  return (
    <>
      {/* <button className="filter-icon" onClick={() => setIsDrawerOpen(true)}>
        <FontAwesomeIcon icon={faFilter} />
      </button> */}
      <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <Filter
          tags={tags}
          selectedTag={selectedTag}
          onTagChange={setSelectedTag}
        />
      </Drawer>

      <div className="tabs">
        <button
          className={`tab-button ${activeTab === "digitals" ? "active" : ""}`}
          onClick={() => setActiveTab("digitals")}
        >
          <span style={{fontFamily: "Fira Sans Condensed, sans-serif"}}><strong>DIGITALS</strong></span>
        </button>
        <button
          className={`tab-button ${activeTab === "portfolio" ? "active" : ""}`}
          onClick={() => setActiveTab("portfolio")}
        >
          <span style={{fontFamily: "Fira Sans Condensed, sans-serif"}}><strong>PORTFOLIO</strong></span>
          </button>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div className="image-gallery">
          {filteredImages.map((image, index) => (
            <div
              key={image.id + "-" + activeTab}
              className={`image-card-container ${
                index % 3 === 1 ? "middle-column" : ""
              }`}
              onClick={() => openModal(image.id)}
            >
              <ImageCard src={image.url} alt={image.description} />
            </div>
          ))}
        </div>
      )}

      <ImageModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        currentImage={filteredImages.find(res => res.id === currentImageId)?.url || ""}
        onNext={nextImage}
        onPrev={prevImage}
      />
    </>
  );
};

export default ImageGallery;
